import React from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import Video from "@components/Video";
import SEO from "@components/SEO";
import Layout from "@components/Layout";

import styles from "@cssmodules/order.module.scss";

export default function Order() {
    return (
        <Layout>
            <main id="main-content" className={styles.main}>
                <SEO title="Cara Order" />
                <Container>
                    <Row className="flex-column-reverse flex-md-row justify-content-between align-items-center mb-5">
                        <Col xs={12} md={6}>
                            <div className={styles.video}>
                                <Video
                                    quality="sd"
                                    title="Cara Order"
                                    link="https://www.youtube.com/embed/tVRvFQIMcaA"
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="pl-md-5">
                            <h2 className={styles.title}>
                                4 Cara Mudah Pesan Jaket
                            </h2>
                            <p className={styles.subtitle}>
                                Selalu memudahkan dan menguntungkan anda
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card
                                className="border-0"
                                style={{
                                    backgroundColor: "#eee",
                                    borderRadius: "1.5rem",
                                }}
                            >
                                <Card.Body className="p-xs-3 p-md-5">
                                    <div className={styles.stepList}>
                                        <h3>Step 1</h3>
                                        <p>
                                            Client memberikan desain / foto kaos
                                            polo yang akan dibuat. Silakan Klik
                                            tombol "Hubungi Kami, Klik Disini!”
                                        </p>
                                    </div>
                                    <div className={styles.stepList}>
                                        <h3>Step 2</h3>
                                        <p>
                                            Apabila klien belum memiliki desain,
                                            kami memiliki alternatif desain yang
                                            bisa dilihat di web kami
                                            Konvekpoloshirt.com
                                        </p>
                                    </div>
                                    <div className={styles.stepList}>
                                        <h3>Step 3</h3>
                                        <p>
                                            Apabila klien mengalami kesulitan
                                            untuk membuat / mengubah desain kaos
                                            polo, kami memiliki tim desain yang
                                            siap membantu men-desain-kan kaos
                                            polo.
                                        </p>
                                    </div>
                                    <div className={styles.stepList}>
                                        <h3>Step 4</h3>
                                        <p>
                                            Setelah desain / foto kaos polo sdh
                                            ada, tim CS kami akan memberikan
                                            penawaran harga. Harga penawaran
                                            bervariasi, tergantung dari jenis
                                            bahan yang dipilih, tingkat
                                            kesulitan pembuatan serta jumlah
                                            yang dipesan.
                                        </p>
                                    </div>
                                    <div className={styles.stepList}>
                                        <h3>Step 5</h3>
                                        <p>
                                            Apabila harga penawaran yang
                                            diberikan sudah disetujui klien,
                                            maka akan berlanjut ke proses
                                            selanjutnya, yang biasa nya bisa
                                            berupa
                                        </p>
                                        <p>
                                            A. Kunjungan ke kantor klien.
                                            Kunjungan ke kantor klien bisa
                                            dilakukan apabila lokasi kantor
                                            klien berapa di wilayah Jakarta,
                                            dengan jumlah pemesanan minimal 50
                                            pcs.
                                        </p>
                                        <p>
                                            B. Pembuatan sample. Pembuatan
                                            sample bisa gratis apabila jumlah
                                            yang dipesan mini- mal 50 pcs,
                                            dengan catatan sample yang dibuat
                                            adalah milik KAMI. Apabila jumlah
                                            yang dipesan kurang dari 50 pcs,
                                            maka harga sample adalah 3x lipat
                                            dari harga penawaran dan baju sample
                                            menjadi milik KLIEN
                                        </p>
                                    </div>
                                    <div className={styles.stepList}>
                                        <h3>Step 6</h3>
                                        <p>
                                            Sebelum pengerjaan massal, klien
                                            berhak untuk menerima Form Approval
                                            dari kami yang berisi hal-hal detil
                                            terkait teknis peme- sanan kaos
                                            polo.
                                        </p>
                                    </div>
                                    <div className={styles.stepList}>
                                        <h3>Step 7</h3>
                                        <p>
                                            Setelah form Approval disetujui oleh
                                            klien, klien membayar DP pembuatan
                                            kaos polo se- besar 50% dari total
                                            transaksi.
                                        </p>
                                    </div>
                                    <div className={styles.stepList}>
                                        <h3>Step 8</h3>
                                        <p>
                                            Apabila klien sudah melakukan DP,
                                            maka pengerjaan massal kaos polo
                                            akan mulai dikerjakan 1 hari setelah
                                            DP diterima oleh kami.
                                        </p>
                                    </div>
                                    <div className={styles.stepList}>
                                        <h3>Step 9</h3>
                                        <p>
                                            Setelah kaos polo selesai
                                            diproduksi, maka kami akan memfoto
                                            kaos polo yang sudah selesai di
                                            produksi sebagai bukti bahwa kaos
                                            polo telah selesai dikerjakan. Foto
                                            kemudian akan kami kirimkan ke
                                            klien.
                                        </p>
                                    </div>
                                    <div className={styles.stepList}>
                                        <h3>Step 10</h3>
                                        <p>
                                            Klien melunasi sisa pembayaran
                                            beserta ongkos pengiriman kaos polo.
                                            Klien bebas memilih ekspedisi yang
                                            digunakan untuk pengiriman kaos
                                            polo.
                                        </p>
                                        <p>
                                            Untuk DP dan Pelunasan hanya
                                            ditransfer ke rekening berikut
                                        </p>
                                    </div>
                                    <div className={styles.payment}>
                                        <p>
                                            BCA : 715-078-873-5 a/n Isnaeni Budi
                                            Prasetyo
                                        </p>
                                        <p>
                                            Mandiri : 157-000-358-687-3 a/n
                                            Isnaeni Budi Prasetyo
                                        </p>
                                        <p>
                                            BRI : 053-801-037-070-502 a/n
                                            Isnaeni Budi Prasetyo
                                        </p>
                                        <p>
                                            BNI : 029-857-603-9 a/n Isnaeni Budi
                                            Prasetyo
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </main>
        </Layout>
    );
}
